import { useEffect, useState, useMemo } from "react";
import { STORAGE } from "../constants";

export const useConfigParams = () => {
    const [confDtos, setConfDtos] = useState([]);

    useEffect(() => {
        const configParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
        let configP = configParams ? JSON.parse(configParams.replace(/\s+/g, "")) : null;
        if (!confDtos.length) setConfDtos(configP && configP.dtos ? configP.dtos : []);
    }, []);

    const getPropLink = (prop) => {
        let item = confDtos.find(x => x.name == prop);
        let link = item && item.value ? item.value : '';
        return link;
    };

    const getQSLink = () => {
        let lang = localStorage.getItem('powr3_lang_code') ?? "en";
        switch (lang) {
            case "en":
            case "jp":
            case 'es':
            case 'pt':
                break;
            case 'es-latam': lang='es';
                break;
            default:
                lang = "other"
                break;
        }

        const qsLink = 'qsguide-' + lang;
        return process.env.REACT_APP_APPURL + '/' + qsLink + '.pdf';
    }

    const allowedTheaters = useMemo(() => {
        try {
            let configParams = sessionStorage.getItem('configParams').replace(/\s+/g, "");
            configParams = JSON.parse(configParams);
            return configParams.dtos.find(x => x.name === 'shipsearch.downloadall.theaters').value;
        }
        catch {
            return "";
        }
    }, []);

    const downloadAllBtnEnabled = useMemo(() => {
        try {
            let configParams = sessionStorage.getItem('configParams').replace(/\s+/g, "");
            configParams = JSON.parse(configParams);
            return configParams.dtos.find(x => x.name === 'shipments.search.downloadallzip.enabled').value === 'true';
        }
        catch {
            return false;
        }
    }, []);

    const commercialDocsEnabled = (prop) => {
        try {
            let configParams = sessionStorage.getItem('configParams').replace(/\s+/g, "");
            configParams = JSON.parse(configParams);
            return configParams.dtos.find(x => x.name === prop).value === 'true';
        }
        catch {
            return false;
        }
    };


    const getFaqLink = () => getPropLink('package.label.instructions.url');
    const getRMAPackagingLink = () => getPropLink('rma.packaging.guidelines.url');
    const getContactUsLink = () => getPropLink('asset.recovery.url');
    const getAssetRecoveryLink = () => getPropLink('assetrecovery.contact');
    const getLogoutLink = () => getPropLink('cisco.auth.logout');
    const getPOWRFaqLink = () => getPropLink('cisco.powr.faq');
    const getSupportReturnsLink = () => getPropLink('cisco.support.returns');
    const getLegalLink = () => getPropLink('cisco.about.legal');
    const getCookiesLink = () => getPropLink('cisco.about.legal.cookies');
    const getQSGuideLink = () => getQSLink();
    const getNewRLSPEnabled = () => getPropLink('RLSP.powr3.enabled');
    const getCommercialInvoiceDisabled = () => getPropLink('gct.CommercialInvoice.disable.user.selection');
    const getSATServiceEnabled = () => getPropLink('sat.service.enabled');
    const getSATServiceEnabledService = () => getPropLink('sat.mx.rfc.service.rmas.enabled') === 'true';
    const getSATServiceEnabledProduct = () => getPropLink('sat.mx.rfc.product.rmas.enabled') === 'true';
    const getSATValidationRequired = () => getPropLink('sat.mx.rfc.validation.required.enabled') === 'true'; 
    const rtfMenuAvailable = () => getPropLink('create.rtf.commercial.invoice') === 'true'; 
    const getSerialNumberValidationEnabled = () => getPropLink('ch.and.fr.serial.number.validation.enabled') === 'true'; 
    const getMultiPackageRestrictedList = () => getPropLink('restricted.multi.package.carriers.service.list'); 
    



    return {
        getFaqLink,
        getRMAPackagingLink,
        getContactUsLink,
        getAssetRecoveryLink,
        getLogoutLink,
        getPOWRFaqLink,
        getSupportReturnsLink,
        getLegalLink,
        getCookiesLink,
        getQSGuideLink,
        getNewRLSPEnabled,
        getCommercialInvoiceDisabled,
        getSATServiceEnabled,
        getSATServiceEnabledService,
        getSATServiceEnabledProduct,
        getSATValidationRequired,
        downloadAllBtnEnabled,
        allowedTheaters,
        rtfMenuAvailable,
        commercialDocsEnabled,
        getSerialNumberValidationEnabled,
        getMultiPackageRestrictedList
    };
};