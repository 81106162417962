import React, { Fragment, useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import {
    selectReturnFlowScheduleStep, selectSchedulePickup, selectPackages, selectUserInfo,
    selectSelectionsInfo, selectShipmentId, selectLabelOnly, selectReturnFlowAttachFiles,
    selectIsFA, selectReturnFlowSavedInfo, selectBaseRMAInfo, selectSublines, selectCategoryCode
} from '../../core/redux/selectors';
import { Input, InputCalendar, Checkbox, CheckRadio, Select, ActionButtons, Upload } from '../controls';
import { getShipCarriers } from '../../services/carrierListService';
import { InfoSection, POWRIcon } from '../styledComponents';
import { setReturnFlowProp } from '../../core/redux/actions';
import { getCommercialLines } from '../../services/rmaService';
import { Spinner } from '../controls';
import { getDHLELPCountries } from '../../services/countryService';
import { getZones, savePickup } from '../../services';
import { useAlert, usePickupDate, useGlobalMessage, useConfigParams, useSerialNumberValidation } from '../../core/hooks';
import { removeShipmentDocuments, saveShipmentDocuments, getDocumentUploadRequired, getDocLanes, getValidateEntities } from '../../services';
import { changeSize, changeWeight } from '../../core/helpers';
import { removeAlertsById, setLoader } from '../../core/redux/actions';
import { REGEXP, COLOR, RETURN, COUNTRY } from '../../core/constants';
import { ViewPackageSNModal } from '../modals';

const SchedulePickup = ({ next, back, step }) => {
    const { translate, langCode } = useTranslate();
    const dispatch = useDispatch();
    const { hasInvalidLines } = useSerialNumberValidation();
    const { showAlert, removeAlert, removeAlerts } = useAlert();
    const { getPickupDates } = usePickupDate();
    const { getFaqLink, getCommercialInvoiceDisabled, getSerialNumberValidationEnabled, getMultiPackageRestrictedList } = useConfigParams();
    const { showPageMessage } = useGlobalMessage(); // Global Messages

    const [palletsNo, setPalletsNo] = useState(0);
    const [dueDate, setDueDate] = useState('');
    const [carrier, setCarrier] = useState('');
    const [time, setTime] = useState(10);
    const [carriers, setCarriers] = useState([]);
    const [palletJack, setPalletJack] = useState(false);
    const [liftTruck, setLiftTruck] = useState(false);
    const [commercial, setCommercial] = useState(false);
    const [showCommercial, setShowCommercial] = useState(false);
    const [firstAvailableDay, setFirstAvailableDay] = useState(null);
    const [serviceDisabledDays, setServiceDisabledDays] = useState([]);
    const [disabledDates, setDisabledDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [labelPreference, setLabelPreference] = useState(false);
    const [showLabelPreference, setShowLabelPreference] = useState(false);
    const [generateSLI, setGenerateSLI] = useState(false);
    const [generatePackingLst, setGeneratePackingLst] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadParams, setUploadParams] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [carrierProfile, setCarrierProfile] = useState(null);
    const [checkCountryFiles, setCheckCountryFiles] = useState(false);
    const [carrierError, setCarrierError] = useState('');
    const [timeError, setTimeError] = useState(false);
    const [errorDueDate, setErrorDueDate] = useState('');
    const [errorPalletNo, setErrorPalletNo] = useState(false);
    const [filePending, setFilePending] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [carrierSelector, setCarrierSelector] = useState(true);
    const [showViewPackage, setShowViewPackage] = useState(false);
    const [linesErrors, setLinesErrors] = useState();
    const [allowMultiPackage, setAllowMultiPackage] = useState(true);

    const dateFormat = sessionStorage.getItem('dateFormat');
    const shipmentId = useSelector(selectShipmentId);
    const labelOnly = useSelector(selectLabelOnly);
    const scheduleStep = useSelector(selectReturnFlowScheduleStep);
    const savedSchedule = useSelector(selectSchedulePickup);
    const packages = useSelector(selectPackages);
    const selectionsInfo = useSelector(selectSelectionsInfo); // Selected entities from SetPickupLocation Step
    const attachedFiles = useSelector(selectReturnFlowAttachFiles);
    const isFA = useSelector(selectIsFA); // Is FACT RETURN flow
    const savedShipment = useSelector(selectReturnFlowSavedInfo);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const subLines = useSelector(selectSublines);
    const categoryCode = useSelector(selectCategoryCode);
    const userInfo = useSelector(selectUserInfo);
    const faqLink = getFaqLink();
    const commercialInvoiceDisable = getCommercialInvoiceDisabled();
    const serialNumberValidationEnabled = getSerialNumberValidationEnabled();
    const multiplePackageRestrictedList = getMultiPackageRestrictedList();
    useEffect(() => {
        setTime(savedSchedule && savedSchedule.pickup ? savedSchedule.pickup.schedule_time_type : 10);
        if (carriers.length) {
            let isDHLAPIAvailable = carriers.find((c) => c.carrier_code === "DHL" && c.service_code.toUpperCase() != "EMAIL" && c.service_code.toUpperCase() != "FTP");
            if (isDHLAPIAvailable) {
                verifyDHLELPCountryActive();
            } else setShowLabelPreference(false);
            // Validate if label preference is selected and mark if user press back button. That validation needs the carrier list to works
            if (savedSchedule) {
                setLabelPreference(savedSchedule.paperless ? true : false);
                setCommercial(savedSchedule.instruct && savedSchedule.instruct.generate_invoice);
            }
        }
    }, [carriers]);

    useEffect(() => {
        if ((shipmentId && scheduleStep)) {
            setShowLoading(true);
            setCarriers([]);
            setShowCommercial(false);
            loadSelectsOptions().then((options) => {
                if (!options.find(x => x.code === carrier) && !savedSchedule) setCarrier('');
                if (options.length === 1) {
                    setCarrier(options[0].code);
                    setCarrierProfile(options[0]);
                }
                if (options.length === 0) handleAlert();
                setCarriers(options);
                if (isFA) {
                    loadDocLanes(shipmentId);
                    setShowLoading(false);
                }
                else {
                    getCommercialLines({ ship_id: shipmentId }).then((response) => {
                        const checkCommercial = response.res.dtos;
                        if (checkCommercial.length > 0) {
                            setShowCommercial(true);
                            if (!savedSchedule) setCommercial(checkCommercial[0].checked_by_default);
                        }
                        setShowLoading(false);
                    }).catch(() => setShowLoading(false));
                }
            }).catch(() => setShowLoading(false));
        }
    }, [palletsNo, shipmentId, scheduleStep, packages, savedSchedule, isFA]);

    useEffect(() => {
        if (labelOnly) {
            setTime(0)
        } else {
            //Validate if schedule time has already selected while user navigated in the return flow
            // setTime(savedSchedule && savedSchedule.pickup ? savedSchedule.pickup.schedule_time_type : 10);
            setTime(10);
        }
        if (showLabelPreference) {
            if ((selectedCarrier && selectedCarrier.carrier_code != "DHL") && (savedSchedule && !savedSchedule.paperless)) setLabelPreference(false);
        }
    }, [carrier]);

    useEffect(() => {
        if (savedSchedule) {
            setCarrier(savedSchedule.carrier ? savedSchedule.carrier.code || savedSchedule.carrier.carrier_code : '');
            setPalletJack(savedSchedule.instruct.equip_pallet_jack);
            setLiftTruck(savedSchedule.instruct.equip_lift_gate);
            setDueDate(savedSchedule.pickup.schedule_date);
            setTime(savedSchedule.pickup.schedule_time_type);
            setPalletsNo(savedSchedule.pickup.oversized_items);
        }
    }, [savedSchedule]);

    useEffect(() => {
        if (savedSchedule && carriers.length) {
            if (savedSchedule.carrier) {
                let savedCarrier = carriers.find(x => x.carrier_profile_id === savedSchedule.carrier.carrier_profile_id);
                setCarrier(savedCarrier ? savedCarrier.code : '')
            }
        }
    }, [savedSchedule, carriers])

    useEffect(() => {
        if (labelPreference) {
            let DHL_carrier = carriers.find(c => c.carrier_code === "DHL");
            setCarrier(DHL_carrier.code);
            setCarrierSelector(false);
        } else {
            setCarrierSelector(true);
        }
    }, [labelPreference]);

    useEffect(() => {
        if (carrierProfile) {
            getTimeZoneData().then(res => {
                let currentLocationDate = res[0] ? res[0].location_date : null;
                if (currentLocationDate && carrierProfile) {
                    let pickupDates = getPickupDates(carrierProfile, currentLocationDate, rmaInfo.theater_code[0]);
                    if (pickupDates.firstDay) {
                        setFirstAvailableDay(moment(pickupDates.firstDay).toDate());
                        setDueDate(savedSchedule && savedSchedule.pickup.schedule_date || moment(pickupDates.firstDay).format("YYYY-MM-DD"));
                    } else {
                        setDueDate(moment(Date.now()).format("YYYY-MM-DD"));
                    }
                    if (pickupDates.noDates < 1) setServiceDisabledDays(carrierProfile.service_disabled_days);
                    setDisabledDates(pickupDates.noDates);
                    setAvailableDates(pickupDates.dates);
                }
            }).catch(err => {
                console.log(err);
            })
            
        }
        setAllowMultiPackage(!(multiplePackageRestrictedList.includes(carrier) && packages.length > 1 && carrier));
    }, [carrierProfile, carrier]);

    useEffect(() => {
        let params = {
            module: 'GCT_Step4',
            country: rmaInfo.country,
            rmaCategory: categoryCode
        }
        showPageMessage(params, '/return');
    }, []);

    const getTimeZoneData = () => new Promise((resolve, reject) => {
        const params = {
            pickup_city: selectionsInfo.pickup.city,
            pickup_province: selectionsInfo.pickup.province,
            pickup_country: selectionsInfo.pickup.country
        }

        getZones(params).then(response => {
            if (response.status === 200) {
                resolve(response.res.dtos);
            } else {
                reject(response);
            }
        }).catch(error => {
            showAlert({ id: 'unexpected', message: translate('message.unexpectedSituation'), page: RETURN, color: 'red' });
            reject(error);
        })
    });

    useEffect(() => {
        if (carriers.length && scheduleStep === 1) {
            setUploadParams({
                ship_id: shipmentId,
                carrier_service_level_id: carriers[0].carrier_service_level_id,
                service_level: carriers[0].service_level
            });
        }
    }, [carriers, showUpload]);

    useEffect(() => {
        if (uploadResponse !== null) {
            setShowUpload(uploadResponse);
            if (uploadResponse) setCheckCountryFiles(attachedFiles.length);
            else setCheckCountryFiles(true);
        } else if (uploadParams) {
            getDocumentUploadRequired(uploadParams).then((response) => {
                const checkResponse = response.res.dtos;
                if (checkResponse[0].required === true) {
                    setShowUpload(true);
                    setUploadResponse(true);
                    setCheckCountryFiles(attachedFiles.length);
                } else {
                    setShowUpload(false);
                    setUploadResponse(false);
                    setCheckCountryFiles(true)
                }
            }).catch(() => console.log('Exception in getDocumentUploadRequired'));
        }
    }, [uploadParams]);

    useEffect(() => {
        if (scheduleStep === 1) {
            if (showUpload && !attachedFiles.length) {
                const alertType = selectionsInfo.pickup.country === COUNTRY.INDIA ? 'attachInformationDC' : 'attachInformationNT';
                showAlert({
                    id: alertType,
                    page: RETURN,
                    message: translate(`message.${alertType}`)
                });
                setCheckCountryFiles(false);
            }
            else if (showUpload) {
                removeAlert(selectionsInfo.pickup.country === COUNTRY.INDIA ? 'attachInformationDC' : 'attachInformationNT');
                setCheckCountryFiles(true);
                setFilePending(false);
            }
        }

    }, [showUpload, scheduleStep, attachedFiles, selectionsInfo.pickup.country, translate]);

    const handleAlert = () => {
        let message = <Fragment>{translate("message.noCarriersAvailable1")} <a target="_blank" className="link" href={faqLink} rel="noopener noreferrer"> {translate("title.clickHere")}</a> {translate("message.noCarriersAvailable2")}</Fragment>
        showAlert({ id: 'noCarriersAvailable', page: RETURN, message, color: "red" });
        setShowLoading(false);

    };

    const verifyDHLELPCountryActive = () => {
        getDHLELPCountries({
            ship_id: shipmentId,
            pickup_country_code: selectionsInfo.pickup.country
        }).then((response) => {
            if (response && response.res.isDhlElpCountryActive === "true") {
                setShowLabelPreference(true);
            } else {
                setShowLabelPreference(false);
            }
        }).catch(() => setShowLabelPreference(false));
    };

    const loadDocLanes = (ship_id) => {
        getDocLanes({ ship_id }).then((response) => {
            if (response.res.success && response.res.dtos.length) {
                const data = response.res.dtos[0];
                if (data.checked_ci_by_default) setCommercial(true);
                if (data.checked_sli_by_default) setGenerateSLI(true);
                if (data.checked_pack_list_by_default) setGeneratePackingLst(true);
            }
        });
    };

    const loadSelectsOptions = () => {
        return getShipCarriers({
            ship_id: shipmentId,
            json: JSON.stringify({ packages }),
            num_pallets: palletsNo,
            is_fact: isFA
        }).then(response => {
            if (response && response.res.success) {
                let formattedCarriers = response.res.dtos.map((carrier) => ({
                    ...carrier,
                    text: `${carrier.descr} (${carrier.carrier_code} - ${carrier.service_code})`,
                    code: carrier.descr,
                }));
                return formattedCarriers;
            }
        }).catch(console.log);
    };

    const handleDueDate = (v, mobile = false) => {
        // Check the date format and value
        let newDate = mobile ? v : moment(v, dateFormat).format('YYYY-MM-DD');
        if (newDate !== "Invalid date") setDueDate(newDate);
    };

    const checkDate = () => {
        // Check the date format and value
        let date = moment(dueDate, 'YYYY-MM-DD').toDate()
        let minDate = firstAvailableDay ? firstAvailableDay : availableDates[0] || moment().startOf('day').toDate();
        let maxDate = availableDates && availableDates[availableDates.length - 1];
        let maxCheck = maxDate ? date <= maxDate : true;
        let minCheck = minDate ? date >= minDate : true;

        return minCheck && maxCheck;
    };

    const checkDisabled = () => {
        let date = moment(dueDate, 'YYYY-MM-DD').toDate()
        let disabledDays = serviceDisabledDays.length > 0;
        let disabledDate = serviceDisabledDays.length ? serviceDisabledDays : disabledDates;
        if (!disabledDate.length) return true;
        let dateT = disabledDate.length && disabledDate.some(disabled => {
            if (disabledDays) return date.getDay() === disabled;
            else return (
                date.getFullYear() === disabled.getFullYear() &&
                date.getMonth() === disabled.getMonth() &&
                date.getDate() === disabled.getDate()
            );
        });
        return !dateT;
    }

    useEffect(() => {
        if (time !== 10) setTimeError(false)
        if (dueDate !== "") setErrorDueDate(false);
        if (carrier !== "") setCarrierError("");
        if (palletsNo) setErrorPalletNo(false);
    }, [time, dueDate, carrier, palletsNo])

    const handleSubmit = async (from) => {

        setIsSubmitting(true);
        if (selectedCarrier && packages && scheduleStep === 1) {
            dispatch(setReturnFlowProp('updateRMA', true));
            
            if (packages.length > selectedCarrier.max_packages_shipment) {
                showAlert({ id: "exceedsWeight", page: RETURN, color: "yellow", message: `${translate("message.exceedsPackages1")} (${packages.length}) ${translate("message.exceedsPackages2")} (${selectedCarrier.max_packages_shipment}).` });
                return;
            }

            let itemsExceeded = packages.filter(a => a.weight > (a.weight_uom !== selectedCarrier.weight_uom ? parseFloat(changeWeight(a.weight_uom, selectedCarrier.max_weight_restriction)) :
                parseFloat(selectedCarrier.max_weight_restriction)));
            if (itemsExceeded.length > 0) {
                showAlert({ id: "exceedsWeight", page: RETURN, color: "yellow", message: translate("message.exceedsWeight") });
                return;
            }
        }
        removeAlert('mandatoryFields');

        if (from === 'resumePickup') {
            saveResumePickup();
        }
        else {
            if (scheduleStep && canSave) {

                //VALIDA SERIALS
                //getInvalidPackages();
                
                let schedulePickup = {
                    carrier: selectedCarrier ? selectedCarrier : savedSchedule ? savedSchedule.carrier ? savedSchedule.carrier : {} : {},
                    pickup: {
                        schedule_date: dueDate || '',
                        schedule_time_type: time !== 10 ? time : '',
                        oversized_items: palletsNo || 0,
                        location_id: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.location_id || 'US-ME' : 'US-ME',
                        ack_returner_pays: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.ack_returner_pays || false : false,
                        gstin: (selectionsInfo && selectionsInfo.pickup) ? (selectionsInfo.pickup.gstin ? selectionsInfo.pickup.gstin : (rmaInfo.gstin || '')) : '',
                        site_directions: savedShipment ? savedShipment.additional : ''
                    },
                    instruct: {
                        is_fact_return: isFA || false,
                        equip_pallet_jack: palletJack || false,
                        equip_heavy_load: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.equip_heavy_load || false : false,
                        generate_sli: generateSLI || false,
                        generate_packing_lst: generatePackingLst || false,
                        generate_invoice: ((showCommercial || isFA) && commercial) ? 'on' : false,
                        equip_lift_gate: liftTruck || false,
                    },
                    notifications: {
                        pickup_notifications: (savedSchedule && savedSchedule.notifications) ? savedSchedule.notifications.pickup_notifications : []
                    },
                    paperless: labelPreference || false
                };
                dispatch(setReturnFlowProp('schedulePickup', schedulePickup));
                if (scheduleStep) {
                    if (!serialNumberValidationEnabled) { 
                        dispatch(setLoader(false));
                        removeAlerts()
                        next();
                    }
                    getValidateEntities({ ship_id: shipmentId }).then(async res => {
                        if (res.res.success && res.res.warn) {
                            removeAlerts()
                            next();
                        }
                        else {
                            setIsSubmitting(true);
                           dispatch(setLoader(true));
                            let lines = await hasInvalidLines(subLines);
                            if (lines === -1) { 
                                setIsSubmitting(false);
                               dispatch(setLoader(false));
                                return;
                            } 
                            if (lines && lines.length > 0) {
                                setLinesErrors(lines);
                                setShowViewPackage(true)
                                setIsSubmitting(false);
                                dispatch(setLoader(false));
                            }
                            else {
                                dispatch(setLoader(false));
                                removeAlerts()
                                next();
                            }
                        }
                    });
                   
                    
                } else {
                    dispatch(setReturnFlowProp('scheduleStep', 1))
                }
            } else if (canContinue && scheduleStep === 0) {
                dispatch(setReturnFlowProp('scheduleStep', 1))
            } else {
                let fieldErrors = [];
                if (filePending)
                    showAlert({ id: "filePending", page: RETURN, color: "yellow", message: translate("placeholder.filePendingUpload") });

                if (selectedCarrier && selectedCarrier.only_paperless_service && showLabelPreference && !labelPreference)
                    showAlert({ id: 'paperless', page: RETURN, message: `${translate('message.paperlessService1')} "${translate('message.paperlessService2')}" ${translate('message.paperlessService3')}`, color: 'yellow' });

                if (carrier === "" && scheduleStep === 1) {
                    setCarrierError(translate("error.required"));
                    fieldErrors.push(translate('gridColumns.carrier'));
                }
                if (!checkDate() || !checkDisabled()) {
                    setErrorDueDate(translate("error.dateDisabledDaysText"));
                }
                if (selectedCarrier && time === 10 && !labelOnly) {
                    setTimeError(true);
                    fieldErrors.push(translate('title.time'));
                }
                if ((dueDate === "" || carrier === "") && scheduleStep === 1 && !labelOnly) {
                    setErrorDueDate(translate("error.required"));
                    fieldErrors.push(translate('title.date'));
                }
                if (packages.find(x => Number(x.weight) === 0))
                    fieldErrors.push(translate('title.weight'));
                if (packages.find(x => Number(Number(x.height) === 0 || Number(x.width) === 0 || Number(x.depth) === 0)))
                    fieldErrors.push(translate('gridColumns.dimensions'));

                if (showUpload && !attachedFiles.length && !filePending)
                    fieldErrors.push(translate('placeholder.uploadFiles'));

                if (!palletsNo && palletsNo !== 0) {
                    setErrorPalletNo(true)
                    fieldErrors.push(translate('formLabel.pallets'));
                }
                if (fieldErrors.length)
                    showAlert({ id: 'mandatoryFields', page: RETURN, message: `${translate('message.mandatoryFieldsPrincipal')} ${fieldErrors.join(', ')}`, color: 'yellow' });
                if (!allowMultiPackage)
                    showAlert({ id: 'deniedMultipackage', page: RETURN, message: `${translate('message.multipackageNotAllowed')}`, color: 'yellow' });
            }
        }
        setIsSubmitting(false);
    };

    const saveResumePickup = () => {
        dispatch(setLoader(true));
        let data = {
            ship_id: shipmentId,
            pickup_id: savedShipment.shipmentId,
            json: {
                packages: packages.map(item => (
                    {
                        ...item,
                        ship_code: `${rmaInfo && rmaInfo.country}${shipmentId || ''}`,
                        serial: subLines && subLines.find(x => x.package_id === item.idd).serial,
                        sn_lookup: subLines && subLines.find(x => x.package_id === item.idd).serial
                    }
                )),
                carrier: selectedCarrier || {},
                pickup: {
                    schedule_date: dueDate || '',
                    schedule_time_type: time !== 10 ? time : '',
                    oversized_items: palletsNo || 0,
                    location_id: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.location_id || 'US-ME' : 'US-ME',
                    ack_returner_pays: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.ack_returner_pays || false : false,
                    gstin: (selectionsInfo && selectionsInfo.pickup) ? (selectionsInfo.pickup.gstin ? selectionsInfo.pickup.gstin : (rmaInfo.gstin || '')) : '',
                    site_directions: savedShipment ? savedShipment.additional : ''
                },
                instruct: {
                    equip_pallet_jack: palletJack || false,
                    equip_heavy_load: (savedSchedule && savedSchedule.pickup) ? savedSchedule.pickup.equip_heavy_load || false : false,
                    generate_invoice: ((showCommercial || isFA) && commercial) ? 'on' : false,
                    equip_lift_gate: liftTruck || false,
                },
                notifications: {
                    pickup_notifications: (savedSchedule && savedSchedule.notifications) ? savedSchedule.notifications.pickup_notifications : []
                },
                paperless: labelPreference || false,
                userDocumentsRequired: savedShipment && savedShipment.attachedFiles.length > 0,
                generate_sli: generateSLI || false,
                generate_packing_lst: generatePackingLst || false,
                is_fact_return: isFA || false
            },
            confirm: false
        };
        savePickup(data).then(() => {
            dispatch(setLoader(false));
        }).catch(() => dispatch(setLoader(false)));
    }

    const toBack = useCallback(() => {
        if (scheduleStep) {
            dispatch(setReturnFlowProp('scheduleStep', 0));
            dispatch(removeAlertsById(['noCarriersAvailable', 'attachInformationDC', 'mandatoryFields']));
        } else {
            removeAlerts();
            back();
            dispatch(removeAlertsById(['noCarriersAvailable', 'attachInformationDC', 'defaultDimensions', 'mandatoryFields']));
        }
    }, [dispatch, scheduleStep, back]);

    const handlePalletsNo = (e) => {
        if (REGEXP.NUMBERS.test(e.target.value)) setPalletsNo(e.target.value);
    };

    const saveDocument = useCallback(async (file) => {
        setFilePending(true);
        const data = {
            session_id: document.cookie.slice(11),
            cisco_user_id: userInfo.cisco_user_id,
            login_email_id: userInfo.cisco_email,
            ship_id: shipmentId,
            file: file
        };
        await saveShipmentDocuments(data).then(response => {
            if (response.dtos[0] && response.dtos[0].idd) {
                let file = {
                    name: response.dtos[0].file_name,
                    size: changeSize(response.dtos[0].file_size),
                    idd: response.dtos[0].idd
                };
                dispatch(setReturnFlowProp('attachedFiles', [...attachedFiles, file]));
                setFilePending(false);
                removeAlert('filePending');
            }
        }).catch(err => console.log(err));
    }, [attachedFiles, dispatch, shipmentId]);

    const removeDocument = useCallback(async (idd) => {
        await removeShipmentDocuments(idd, shipmentId).then(response => {
            if (response.res.success)
                dispatch(setReturnFlowProp('attachedFiles', [...attachedFiles.filter(x => x.idd !== idd)]));
        }).catch(err => console.log(err));
    }, [attachedFiles, dispatch, shipmentId]);

    const selectedCarrier = useMemo(() => {
        let selected = (carriers && carriers.length > 0) ? carriers.find(x => x.code === carrier) : null;
        if (selected) setCarrierProfile(selected);
        return selected;
    }, [carrier, carriers]);

    const canSave = useMemo(() =>
        dueDate && carrier && selectedCarrier && time !== 10 && checkCountryFiles && !filePending && checkDate() && checkDisabled() && allowMultiPackage &&
        !packages.find(x => Number(x.weight) === 0 || Number(x.height) === 0 || Number(x.width) === 0 || Number(x.depth) === 0) &&
        (!selectedCarrier.only_paperless_service || (selectedCarrier.only_paperless_service && labelPreference))
        , [dueDate, carrier, packages, time, checkCountryFiles, selectedCarrier, labelPreference, filePending, allowMultiPackage]);

    const displayLabelJp = useMemo(() => {
        return selectedCarrier && langCode === 'jp' && ((selectedCarrier.any_pickup_available && !selectedCarrier.am_pickup_available && !selectedCarrier.pm_pickup_available) ||
            (selectedCarrier.am_pickup_available && !selectedCarrier.any_pickup_available && !selectedCarrier.pm_pickup_available) ||
            (selectedCarrier.pm_pickup_available && !selectedCarrier.any_pickup_available && !selectedCarrier.am_pickup_available) )
    }
    ,[selectedCarrier]);

    const canContinue = useMemo(() => !packages.find(x => Number(x.weight) === 0 || Number(x.height) === 0 || Number(x.width) === 0 || Number(x.depth) === 0) && palletsNo.toString() !== "", [packages, palletsNo]);

    useEffect(() => {
        if (selectedCarrier) {
            if (labelOnly) setTime(0);
            else {
                if (selectedCarrier.any_pickup_available && !selectedCarrier.am_pickup_available && !selectedCarrier.pm_pickup_available)
                    setTime(0)
                if (selectedCarrier.am_pickup_available && !selectedCarrier.any_pickup_available && !selectedCarrier.pm_pickup_available)
                    setTime(1)
                if (selectedCarrier.pm_pickup_available && !selectedCarrier.any_pickup_available && !selectedCarrier.am_pickup_available)
                    setTime(2)
            }
            setLabelPreference(
                !selectedCarrier || selectedCarrier.carrier_code !== 'DHL' ? false :
                savedSchedule && savedSchedule.paperless ? savedSchedule.paperless : labelPreference
            );
        }
    }, [selectedCarrier]);

    const handleTest = () => { 
        dispatch(setLoader(true));
        setTimeout(() => { 
            dispatch(setLoader(false));
        }, 4000);
    }
    return (
        <Fragment>
            <div className="schedulePickUp">
                <div className="infoContentBox">
                    <h3>{translate('gridColumns.shipmentID')}: {`${selectionsInfo ? selectionsInfo.pickup.country : ''}${shipmentId || ''}`}</h3>
                </div>
                {!scheduleStep ?
                    <InfoSection type="taskBar" hideBorders title={translate('title.specialEquipment')}>
                        <div className="infoBody pt-16">
                            <Checkbox id="palletJack" value={palletJack} checked={palletJack} onClick={() => setPalletJack(!palletJack)} text={translate('aux.requiresPallets')} />
                        </div>
                        <div className="infoBody">
                            <Checkbox id="liftTruck" value={liftTruck} checked={liftTruck} onClick={() => setLiftTruck(!liftTruck)} text={translate('aux.requiresLiftTruck')} />
                        </div>
                        <Input
                            value={palletsNo}
                            onChange={handlePalletsNo}
                            label={translate('formLabel.pallets')}
                            placeholder={translate('placeholder.numberOfPallets')}
                            className='input-palets'
                            error={errorPalletNo && translate('error.required')} />
                    </InfoSection>
                    :
                    <Fragment>
                        <InfoSection
                            type="taskBar"
                            title={translate('title.specialEquipment')}
                            hideBorders
                            buttonText={translate('button.edit')}
                            buttonSymbol={<POWRIcon className="fas fa-pen" color={COLOR.CISCO_BLUE} />}
                            onButtonClick={() => dispatch(setReturnFlowProp('scheduleStep', 0))}>
                            {
                                (!palletJack && !liftTruck) ?
                                    <label className="d-block m-2 mr-1">{translate('aux.na')}</label>
                                    :
                                    <Fragment>
                                        {palletJack && <label className="d-block m-2 mr-1">{translate('placeholder.palletJack')}</label>}
                                        {liftTruck && <label className="d-block m-2 mr-1">{translate('placeholder.liftTruck')}</label>}
                                        <label className="d-block m-2 mr-1">{translate('placeholder.numberOfPallets')} {palletsNo}</label>
                                    </Fragment>
                            }
                        </InfoSection>
                        {carriers && carriers.length > 0 ?
                            <InfoSection type="taskBar" hideBorders className="pt-16" title={labelOnly ? translate('title.generateLabelOnly') : translate('formLabel.sCarrier')}>
                                <div className="infoBody">
                                    <Select
                                        value={carrier}
                                        onChange={(val) => setCarrier(val)}
                                        options={carriers}
                                        className="inputSpace"
                                        singleSelection
                                        label={translate('formLabel.shipCarrier')}
                                        placeholder={translate('formLabel.shipCarrier')}
                                        disabled={!carrierSelector}
                                        error={carrierError} />
                                    {
                                        !labelOnly &&
                                        <InputCalendar
                                            minDate={firstAvailableDay ? firstAvailableDay : availableDates[0] || moment().startOf('day').toDate()}
                                            maxDate={availableDates && availableDates[availableDates.length - 1]}
                                            text={dueDate ? moment(dueDate).format(dateFormat) : ''}
                                            value={dueDate ? moment(dueDate).format("DD/MM/YY") : ''}
                                            onBlur={(v, m) => handleDueDate(v, m)}
                                            disabled={!selectedCarrier}
                                            label={translate('title.date')}
                                            placeholder={dateFormat}
                                            className="mb-3 pr-1"
                                            disabledDays={serviceDisabledDays.length > 0}
                                            disabledDates={serviceDisabledDays.length ? serviceDisabledDays : disabledDates}
                                            error={errorDueDate}
                                            checkMinMax={false}
                                        />
                                    }
                                </div>
                                {!labelOnly &&
                                    <div className="pt-16">
                                        <h3>{translate("title.time")}</h3>
                                        <div className="d-flex flex-column">
                                            {selectedCarrier && selectedCarrier.any_pickup_available &&
                                                <CheckRadio
                                                    id={'time1'}
                                                    name="time"
                                                    text={translate("scheduleTimeType.any")}
                                                    checked={time === 0}
                                                    onChange={() => setTime(0)}
                                                    error={timeError} />
                                            }
                                            {selectedCarrier && selectedCarrier.am_pickup_available &&
                                                <CheckRadio
                                                    id={'time2'}
                                                    name="time"
                                                    text={translate("scheduleTimeType.am")}
                                                    checked={time === 1}
                                                    onChange={() => setTime(1)}
                                                    error={timeError} />
                                            }
                                            {selectedCarrier && selectedCarrier.pm_pickup_available &&
                                                <CheckRadio
                                                    id={'time3'}
                                                    name="time"
                                                    text={translate("scheduleTimeType.pm")}
                                                    checked={time === 2}
                                                    onChange={() => setTime(2)}
                                                    error={timeError} />
                                            }
                                        </div>
                                       {selectedCarrier && displayLabelJp &&  <p>{translate("scheduleTimeType.anyJp")}</p>}
                                    </div>
                                }
                            </InfoSection>
                            : showLoading &&
                            <Spinner
                                className="ml-40"
                                size="70px"
                                color={COLOR.CISCO_BLUE} />
                        }
                        {(showUpload) &&
                            <InfoSection type="taskBar" hideBorders className="pt-16" title={translate('title.documentationUpload')}>
                                <div className="infoBody">
                                    {selectionsInfo.pickup.country === COUNTRY.BRAZIL &&
                                        <><h4 className='m-0 mt-3'>{translate("title.notaFiscal")}</h4>
                                            <label>{translate("message.msgNotaFiscal")}</label>
                                        </>
                                    }
                                    <Upload
                                        attachedFiles={attachedFiles}
                                        onAddFile={(file) => saveDocument(file)}
                                        onRemoveFile={(idd) => removeDocument(idd)} />
                                </div>
                            </InfoSection>
                        }
                        {(!isFA && showCommercial) &&
                            <InfoSection type="taskBar" className="pt-16" hideBorders title={translate("title.commercialInvoice")}>
                                <div className="infoBody mt-3">
                                    <Checkbox disabled={ commercialInvoiceDisable === 'true' } id="commercialInv" checked={commercial} onClick={() => setCommercial(!commercial)} text={translate("title.generateCommercialInvoice")} />
                                </div>
                            </InfoSection>
                        }
                        { //FACT RETURN
                            isFA &&
                            <InfoSection type="taskBar" className="pt-16" hideBorders title={translate("title.shippingDocs")}>
                                <div className="infoBody mt-3">
                                    <Checkbox id="commercialInv" checked={commercial} onClick={() => setCommercial(!commercial)} text={translate("title.generateCommercialInvoice")} />
                                    <Checkbox id="SLI-cbox" checked={generateSLI} onClick={() => setGenerateSLI(!generateSLI)} text={translate("title.generateSLI")} />
                                    <Checkbox id="packing-cbox" checked={generatePackingLst} onClick={() => setGeneratePackingLst(!generatePackingLst)} text={translate("title.generatePackingList")} />
                                </div>
                            </InfoSection>
                        }
                        {showLabelPreference &&
                            <InfoSection type="taskBar" className="pt-16" hideBorders title={translate(`title.labelPreference`)}>
                                <div className="infoBody mt-3">
                                    <Checkbox checked={labelPreference} onClick={() => setLabelPreference(!labelPreference)} text={translate("formLabel.carrierToBringLabel")} />
                                </div>
                            </InfoSection>
                        }
                    </Fragment>
                }
                {/* <button onClick={handleTest}>prueba </button> */}
            </div>

            {!showLoading &&
                <ActionButtons
                    secondary={() => toBack()}
                    sText={translate('button.back')}
                    primary={() => handleSubmit()}
                    pText={translate(`button.${scheduleStep ? 'next' : 'continue'}`)}
                    isLoading={isSubmitting}
                    completed={!isSubmitting}
                    step={step} />
            }
            {/* <button onClick={handleTest}>click me</button> */}
            {
                showViewPackage &&
                <ViewPackageSNModal
                    showViewPackage={showViewPackage}
                    setShowViewPackage={setShowViewPackage}
                    shipId={shipmentId}
                    isSerialEditable
                    linesErrors={linesErrors}
                />}
        </Fragment>
    );
}

export default SchedulePickup;